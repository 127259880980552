import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {IsAddVideoOpen, IsAdmin, Lang, UpdateVideo} from "../state/atoms";
import DeleteVideoButton from "../components/Buttons/DeleteVideo";
import AddVideoModal from '../components/Modals/CreateVideo';
import {Helmet} from "react-helmet";
import {dictionary} from "../constants/dictionary";
import {api_url} from "../config";
import Page from '../skeletons/Page';
const Video = () => {
    const isAdmin = useRecoilValue(IsAdmin);
    const [videos, setVideos] = useState([]);
    const setOpen = useSetRecoilState(IsAddVideoOpen);
    const updateVideo = useRecoilValue(UpdateVideo);
    const lang = useRecoilValue(Lang);
    const langDictionary = dictionary[lang] || {};
    const [load, setLoad] = useState(true);

    useEffect(() => {
        setLoad(true);
        async function getVideo() {
            try {
                const response = await fetch(`${api_url}/video`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                setVideos(response.status === 200 ? await response.json() : []);
            } catch (e) {
                console.log(e);
            }
        }
        getVideo();
        setTimeout(()=>{
            setLoad(false);
        }, 1000)
    }, [updateVideo]);
    return (
        <Wrapper>
            <Helmet>
                <title>{langDictionary.homePageItem3Title}</title>
                <meta name="description" content= {langDictionary.homePageItem3Title} />
            </Helmet>
            <AddVideoModal/>
            <Content>
                <TitleContainer>
                    <BackButton to={'/'}>
                        <svg width="20px" height="20px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/><path fill="#fff" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/></svg>
                    </BackButton>
                    <Title>
                        {langDictionary.homePageItem3Title}
                    </Title>
                    {isAdmin &&
                        <AddButton onClick={()=> {
                            setOpen(true);
                        }}>
                            <span>
                            <span style={{display: 'flex'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" className="yc-icon" fill="currentColor" stroke="none" aria-hidden="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
                                        <path fill="currentColor" fillRule="evenodd" d="M8 1.75a.75.75 0 0 1 .75.75v4.75h4.75a.75.75 0 0 1 0 1.5H8.75v4.75a.75.75 0 0 1-1.5 0V8.75H2.5a.75.75 0 0 1 0-1.5h4.75V2.5A.75.75 0 0 1 8 1.75Z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </svg>
                            </span>
                        </span>
                        </AddButton>
                    }
                </TitleContainer>

                <DocsContainer>
                    {!load ? 
                        <>
                            {videos.length > 0 && videos.map((item,index)=> (
                                <DocItem key={index}>
                                    <div style={{display: 'flex', alignItems: 'center', gap: '20px', alignSelf: 'start'}}>
                                        {isAdmin && <DeleteVideoButton id={item._id}/>}
                                        <DocText>{lang === 'ru' ? item.title : item.titleEng}</DocText>
                                    </div>
                                    <iframe width="100%" height="315"
                                            src={item.link}
                                            title="YouTube video player" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen></iframe>
                                </DocItem>
                            ))}
                        </>
                    :
                    <Page/>
                    }
                </DocsContainer>
            </Content>
            <Footer/>
        </Wrapper>
    );
};

export default Video;
const VideoContainer = styled.video`
  width: 100%;
  background: transparent;
`
const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  letter-spacing: 0.5px;
`
const Title = styled.h1`
    line-height: 28px;
  margin-bottom: 0;
`
const DocsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 10px;
  padding-bottom: 30px;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 15px 0;
`
const TitleContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`
const AddButton = styled.button`
  width: 28px;
  height: 28px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff;
  background: transparent;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  transition: all .15s ease-out;
  &:hover {
    background: rgba(255, 255, 255, 0.25);
  }
`
export const BackButton = styled(Link)`
  width: 28px;
  height: 28px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff;
  background: transparent;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  transition: all .15s ease-out;
  &:hover {
    background: rgba(255, 255, 255, 0.25);
  }
`
const DocItem = styled.a`
  width: 50%;
  background: transparent;
  display: flex;
  align-items: center;
  gap:20px;
  padding:10px;
  border-bottom: 2px solid #5e5c5c;
  flex-direction: column;
  @media screen and (max-width: 880px){
    width: 100%;
  }
`
const DocText = styled.p`
    font-size: 15px;
    margin: 0;
`
