import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import styled from 'styled-components'
import Menu from "../../skeletons/Menu";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {blockScroll, Lang, MenuItems, SelectedPage, UpdateSidebarFlag} from '../../state/atoms'
import AddPageButton from '../Buttons/AddPage'
import DeletePageButton from "../Buttons/DeletePage";
import {BackButton} from '../../pages/Docs'
import {api_url} from "../../config";
const MenuItem = ({ item, level, setOpen }) => {

    const [submenuOpen, setSubmenuOpen] = useState(item.opened);
    const selectedPage = useRecoilValue(SelectedPage);
    const navigate = useNavigate();
    const lang = useRecoilValue(Lang);
    const toggleSubmenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setSubmenuOpen(!submenuOpen);
    };
    const handleClick = (e, link) => {
        e.stopPropagation();
        e.preventDefault();
        navigate(`/knowledge-base${link}`);
        setOpen(false);
    }
    return (
        <NavigationTreeItem>
            <TreeMenuItem onClick={(e)=> handleClick(e, item.link)} to={`/knowledge-base${item.link}`} className={selectedPage === item.link ? 'opened' : ''}>
                <OpenTreeButton onClick={(e) => toggleSubmenu(e)} style={{marginLeft: `${level * 10}px`}}>
                    {item.submenu && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 16 16" className={submenuOpen ? 'opened' : ''}>
                            <path fill="currentColor" fillRule="evenodd" d="M6.705 11.823a.73.73 0 0 1-1.205-.552V4.729a.73.73 0 0 1 1.205-.552L10.214 7.2a1 1 0 0 1 .347.757v.084a1 1 0 0 1-.347.757l-3.509 3.024Z" clipRule="evenodd"></path>
                        </svg>
                    )}
                </OpenTreeButton>
                <span>{lang === 'ru' ? item.title : item.titleEng}</span>
                <ButtonWrapper>
                    <AddPageButton link={item.link}/>
                    <DeletePageButton link={item.link}/>
                </ButtonWrapper>
            </TreeMenuItem>
            {submenuOpen && item.submenu && (
                <div>
                    {item.submenu.map((subitem, index) => (
                        <MenuItem setOpen={setOpen} key={index} item={subitem} level={level+1}/>
                    ))}
                </div>
            )}
        </NavigationTreeItem>
    );
};

const Index = () => {

    const [menu, setMenu] = useRecoilState(MenuItems);
    const [loading, setLoading] = useState(true);
    const setBlockScroll = useSetRecoilState(blockScroll);
    const [open, setOpen] = useState(false);
    const [responseData, setResponseData] = useState([]);
    const selectedPage = useRecoilValue(SelectedPage);
    const needUpdateSidebar = useRecoilValue(UpdateSidebarFlag);

    function setSubmenuOpenRecursive(menu, selectedPage) {
        for (const item of menu) {
            if (item.link === selectedPage) {
                let parent = item;
                while (parent) {
                    if (parent.submenu) {
                        parent.opened = true;
                    }
                    parent = parent.parent;
                }
            }
            if (item.submenu) {
                item.submenu.forEach(subitem => {
                    subitem.parent = item;
                });
                setSubmenuOpenRecursive(item.submenu, selectedPage);
            }
        }
    }
    function createMenuHierarchy(menuItems, parent = '/') {
        const topLevelItems = menuItems.filter((item) => item.parent === parent);
        if (topLevelItems.length === 0) {
            return [];
        }
        return topLevelItems.map((item) => {
            const submenu = createMenuHierarchy(menuItems, item.link);
            return submenu.length > 0
                ? {
                    titleEng: item.titleEng,
                    title: item.title,
                    link: item.link,
                    submenu,
                }
                : {
                    titleEng: item.titleEng,
                    title: item.title,
                    link: item.link,
                };
        });
    }

    useEffect(() => {
         const  fetchData = async () =>{
            try {
                const response = await fetch(`${api_url}/link`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                setResponseData( await response.json());
            } catch (error) {
                console.log("Error fetching data:", error);
            }
        }
        fetchData();
        setTimeout(()=>{
            setLoading(false);
        }, 500)
    }, [needUpdateSidebar]);

    useEffect(() => {
        const menuTree = createMenuHierarchy(responseData);
        setSubmenuOpenRecursive(menuTree, selectedPage);
        setMenu(menuTree);
        // eslint-disable-next-line
    }, [responseData,selectedPage]);

    useEffect(() => {
        setBlockScroll(open);
        // eslint-disable-next-line
    }, [open]);

    return (
        <Sidebar opened={open.toString()}>
            <SidebarHeader>
                <div style={{display: 'flex', gap: '15px', alignItems: 'center'}}>
                    <BackButton to={'/'}>
                        <svg width="20px" height="20px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/><path fill="#fff" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/></svg>
                    </BackButton>
                    <OpenButton onClick={()=> setOpen(!open)} >
                        {open ?
                            <svg width="18px" height="18px" viewBox="0 -0.5 21 21" xmlns="http://www.w3.org/2000/svg">
                                <g stroke="none" strokeWidth="1" fill="#fff" fillRule="evenodd">
                                    <g transform="translate(-419.000000, -240.000000)" fill="#fff">
                                        <g id="icons" transform="translate(56.000000, 160.000000)">
                                            <polygon points="375.0183 90 384 98.554 382.48065 100 373.5 91.446 364.5183 100 363 98.554 371.98065 90 363 81.446 364.5183 80 373.5 88.554 382.48065 80 384 81.446">
                                            </polygon>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            :
                                <svg width="24px" height="24px" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <rect fill="#fff" height="1" width="11" x="0.5" y="5.5"/>
                                        <rect fill="#fff" height="1" width="11" x="0.5" y="2.5"/>
                                        <rect fill="#fff" height="1" width="11" x="0.5" y="8.5"/>
                                    </g>
                                </svg>
                        }
                    </OpenButton>
                    <Link to={'/knowledge-base'} onClick={()=>setOpen(false)}>ODRS</Link>
                </div>
                <AddPageButton link={'/'}/>
            </SidebarHeader>
            <NavigationTree opened={open.toString()}>
                {loading ?
                    <Menu/>
                    :
                    <>
                        {menu.map((item,index) => (
                            <MenuItem setOpen={setOpen} key={index} item={item} level={0}/>
                        ))}
                    </>
                }
            </NavigationTree>
        </Sidebar>
    );
};

export default Index;

const Sidebar = styled.nav`
  height: 100vh;
  background-color: #474747;
  width: 400px;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  left: 0;
  @media screen and (max-width: 768px){
    position: fixed;
    width: 100vw;
    z-index: 999;
    height: ${props => props.opened === 'true' ? '100%' : '50px'};
  }
  @media screen and (min-width: 1440px){
    width: 390px;
  }
  @media screen and (min-width: 1920px){
    width: 420px;
  }
`
const SidebarHeader = styled.div`
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 0 8px;
  a {
    border-radius: 4px;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    margin-right: 8px;
    overflow: hidden;
    -webkit-text-decoration: none;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media screen and (max-width: 768px){
    min-height: 50px;
    border: none;
    padding: 0 15px;
    a {
      font-size: 18px;
    }
  }
  
`
const NavigationTree = styled.div`
  padding-bottom: 40px;
  position: relative;
  overflow: auto;
  will-change: transform;
  @media screen and (max-width: 768px){
    display: ${props => props.opened === 'true' ? 'flex' : 'none'};
    flex-direction: column;
  }
`
const NavigationTreeItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  transition: opacity .5s ease;
  width: 100%;
`
const TreeMenuItem = styled(Link)`
  font-size: 14px;
  font-weight: 400;
  min-height: 32px;
  display: flex;
  align-items: center;
  transition: all .1s linear;
  padding-right: 8px;
  @media screen and (max-width: 768px){
    font-size: 16px;
    min-height: 40px;
  }
  button {
    display: none;
    width: 24px;
    margin-left: auto;
    
  }
    &:hover {
      background: rgba(255, 255, 255, 0.15);
      @media screen and (max-width: 768px){
        background: transparent;
      }
    button {
      display: flex;
      }
    }
    &.opened {
      background: rgba(255, 255, 255, 0.15);
    }
    span {
      max-width: 195px;
      word-break: break-word;
      @media screen and (max-width: 768px){
        max-width: 100%;
      }
    }
`
const OpenTreeButton = styled.div`
  height: 28px;
  width: 28px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  .opened {
    transform: rotate(90deg);
  }
  @media screen and (max-width: 768px){
    svg {
      height: 16px;
      width: 16px;
    }
  }
`
const OpenButton = styled.div`
  display: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  -webkit-tap-highlight-color: transparent;
  @media screen and (max-width: 768px){
   display: flex;
    justify-content: center;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  min-width: 50px;
`