import React, { useEffect } from 'react';
import {Link} from "react-router-dom";
import styled from 'styled-components'
import Footer from "../components/Footer";
import {useSetRecoilState, useRecoilValue} from "recoil";
import {Lang, SupportModal} from "../state/atoms";
import Support from "../components/Modals/Support";
import Alert from '../components/Alert'
import {Helmet} from "react-helmet";
import {dictionary} from '../constants/dictionary'
import LangSwitcher from '../components/LanguageSwitcher';

const Home = () => {
    const setModal = useSetRecoilState(SupportModal);
    const lang = useRecoilValue(Lang);
    const langDictionary = dictionary[lang] || {};
    useEffect(()=>{
      document.body.style.overflow = '';
    },[])
    return (
    <PageWrapper>
        <Helmet>
            <title>{langDictionary.homePageTitle}</title>
            <meta name="description" content={langDictionary.homePageTitle} />
        </Helmet>
        <Alert/>
        <Container>
            <LangSwitcher/>
            <TextWrapper>
                <Title>{langDictionary.homePageMainText} <br/><span>{langDictionary.homePageSpanText}</span></Title>
                <SubTitle>{langDictionary.homePageSubTitle}</SubTitle>
            </TextWrapper>
            <LinkContainer>
                <StyledLink to={`knowledge-base`} color={'#00FFFFFF'}>
                    <StyledLetter color={'#2e9b9b'} translate="no">KB</StyledLetter>
                    <h4>{langDictionary.homePageItem1Title}</h4>
                    <p>{langDictionary.homePageItem1Text}</p>
                </StyledLink>
                <Divider/>
                <StyledLink to={`docs`} color={'#00FFFFFF'}>
                    <StyledLetter color={'#2e9b9b'} translate="no">Doc</StyledLetter>
                    <h4>{langDictionary.homePageItem2Title}</h4>
                    <p>{langDictionary.homePageItem2Text}</p>
                   </StyledLink>
                <Divider/>
                <StyledLink to={`video-tutorials/`} color={'#00FFFFFF'}>
                    <StyledLetter color={'#2e9b9b'} translate="no">Vid</StyledLetter>
                    <h4>{langDictionary.homePageItem3Title}</h4>
                    <p>{langDictionary.homePageItem3Text}</p>
                </StyledLink>
            </LinkContainer>
        </Container>
        <SupportContainer>
            <ShadowTop/>
            <Container>
                <TextWrapper>
                    <Title style={{fontSize: '36px'}}>{langDictionary.homePageSupportTitle} <span>{langDictionary.homePageSupportSpan}</span></Title>
                    <SubTitle style={{fontSize: '18px'}}>{langDictionary.homePageSupportSubTitle}</SubTitle>
                    <SupportButton onClick={() => setModal(true)}>
                        {langDictionary.homePageTextButton}
                    </SupportButton>
                </TextWrapper>
            </Container>
            <Support/>
            <ShadowBot/>
        </SupportContainer>
        <Footer/>

    </PageWrapper>
    )
}

export default Home;

const SupportButton = styled.button`
    margin-top: 20px;
    font-size: 16px;
    padding: 18px 25px;
    color: #363636;
    border: none;
    background:  rgb(0, 255, 255);
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s linear;
    &:hover {
      background: rgba(0, 255, 255, 0.7);
    }
`

const SupportContainer = styled.div`
  width: 100%;
  background: #2d2c2c;
  text-align: center;
  position: relative;
`
const ShadowTop = styled.div`
  width: 100%;
  position: absolute;
  top:0;
  bottom: 0;
  background: linear-gradient(to bottom,#232323 , #2d2c2c );
  height: 15%;
`
const ShadowBot = styled.div`
  width: 100%;
  position: absolute;
  bottom:0;
  background: linear-gradient(to bottom, #2d2c2c, #232323 );
  height: 15%;
`

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const Container = styled.section`
  width: 100%;
  letter-spacing: 0.5px;
  padding-left: 15px;
  padding-right: 15px;
`
const TextWrapper = styled.div`
  padding-top: 60px;
  padding-bottom: 80px;
  box-sizing: border-box;
  @media screen and (max-width: 880px){
    padding-top: 40px;
    padding-bottom: 40px;
  }
`
const Title = styled.h1`
  font-size: 45px;
  text-align: center;
  font-weight: 300; 
  line-height: 60px;
  span {
    color: rgb(0, 255, 255);
    font-weight: 600;
  }
  @media screen and (max-width: 880px){
    font-size: 30px;
    line-height: 36px;
  }
`
const SubTitle = styled.h2`
    padding-top: 20px;
    font-size: 24px;
    text-align: center;
    font-weight: 300;
  @media screen and (max-width: 880px){
    font-size: 18px;
    line-height: 36px;
  }
`
const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: start;
  padding-bottom: 30px;
  @media screen and (max-width: 1024px){
    gap: 15px;
  }
  @media screen and (max-width: 880px){
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`
const StyledLink = styled(Link)`
  width: 370px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  word-break: break-word;
  transition: all 0.5s linear;
  
  h4 {
    font-weight: 500;
    font-size: 27px;
  }
  p {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
  }
  &:hover {
    h2 {
      color: ${props => props.color ? props.color : 'white'};
    }
  }
  @media screen and (max-width: 880px){
    width: 100%;
    padding: 0 15px;
  }
`
const StyledLetter = styled.h2`
    font-size: 130px;
    font-weight: 800;
    line-height: 100%;
    margin-top: 10px;
  transition: all 0.3s linear;
    color:  ${props => props.color ? props.color : 'white'};`
const Divider = styled.div`
  height: 180px;
  width: 2px;
  background: #5e5c5c;
  @media screen and (max-width: 1024px){
    display: none;
  }
`
