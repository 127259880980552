import React, {useEffect, useRef, useState} from 'react';
import {useRecoilState, useSetRecoilState} from "recoil";
import {blockScroll, IsCreateOpen, MenuItems, SelectedPage, UpdateSidebarFlag} from "../../../state/atoms";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import styled from 'styled-components';
import {useNavigate} from "react-router-dom";
import {api_url} from "../../../config";
import {image_path} from "../../../config";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '640px',
    bgcolor: '#474747',
    borderRadius: '8px',
    boxShadow: 24,
};

const Index = () => {
    const [isOpen, setOpen] = useRecoilState(IsCreateOpen);
    const [title, setTitle] = useState({value: '', err: ''});
    const [titleEng, setTitleEng] = useState({value: '', err: ''});
    const [link, setLink] = useState({value: '', err: ''});
    const [content, setContent] = useState({value: '', err: ''});
    const [contentEng, setContentEng] = useState({value: '', err: ''});
    const [file, setFile] = useState([]);
    const [fileEng, setFileEng] = useState([]);
    const [load, setLoad] = useState(false);
    const setBlockScroll = useSetRecoilState(blockScroll);
    const [menuItems, setMenuItems] = useRecoilState(MenuItems);
    const [updateFlag,needUpdateSidebar] = useRecoilState(UpdateSidebarFlag);
    const setSelectedPage = useSetRecoilState(SelectedPage);
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const fileInputRefEng = useRef(null);
    const photoInputRef = useRef(null);
    const [photos, setPhotos] = useState([]);
    const [notUploaded, setNotUploaded] = useState([]);
    const [photoNeedToUpload, setNeedPhoto] = useState([]);

    function replaceImagePaths(htmlString) {
        const regex = /<img ([^>]*)src="([^"]*)"/g;
        return htmlString.replace(regex, (match, attributes, src) => {
            return `<img ${attributes} src="${image_path}/${src}"`;
          });
      }

    const handleClose = () => {
        setOpen({value: false, link: ''});
    }
    const handleInputChange = (e, setState) => setState({err: '', value: e.target.value});
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFile(file);
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const file_content = e.target.result;

                setContent({value:  replaceImagePaths(file_content), err: ''});
            };
            reader.readAsText(file);
        }
    }

    const handlePhotoSelect = (event) => {
        setPhotos([...event.target.files]);
        setNotUploaded([]);
    };

    const handleFileChangeEng = (e) => {
        const file = e.target.files[0];
        setFileEng(file);
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const file_content = e.target.result;
                setContentEng({value: replaceImagePaths(file_content), err: ''});
            };
            reader.readAsText(file);
        }
    }
    const isInputValid = () => {
        let isValid = true;
        if (title.value.trim().length === 0) {
            setTitle({...title, err: 'Необходимо заполнить поле'})
            isValid = false;
        }
        if (titleEng.value.trim().length === 0) {
            setTitleEng({...titleEng, err: 'Необходимо заполнить поле'})
            isValid = false;
        }
        if (link.value.trim().length === 0) {
            setLink({...link, err: 'Необходимо заполнить поле'})
            isValid = false;
        }
        if (content.value.trim().length === 0) {
            setContent({...content, err: 'Необходимо заполнить поле'})
            isValid = false;
        }
        if (contentEng.value.trim().length === 0) {
            setContentEng({...contentEng, err: 'Необходимо заполнить поле'})
            isValid = false;
        }
        return isValid;
    }
    function extractImageFilenames(htmlString) {
        // Parse the HTML string
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
      
        // Find all image elements
        const images = doc.querySelectorAll('img');
        
        // Extract the filenames from the src attribute of each image
        const filenames = Array.from(images).map(img => {
          const src = img.getAttribute('src');
          return src.split('/').pop(); // Assuming the filename is after the last '/'
        });
        return filenames;
      }
    const isPhotoValid = () => {
        let photosName = extractImageFilenames(content.value);
        let photosNameEng = extractImageFilenames(contentEng.value);
        const combinedUniqueArray = Array.from(new Set(photosName.concat(photosNameEng)));
        const photoUploaded = photos.map((item)=> item.name);
        const _notUploaded = combinedUniqueArray.filter(photo => !photoUploaded.includes(photo));
         setNotUploaded(_notUploaded);
        return _notUploaded.length === 0;
    }
    function addSubMenuRecursive(menu) {
        if (isOpen.link === '/') {
            return [...menu, {title: title.value, link: '/'+link.value}];
        } else
        return menu.map((item) => {
            if (item.link === isOpen.link) {
                const updatedSubmenu = item.submenu
                    ? item.submenu.concat({title: title.value, link: '/'+link.value})
                    : [{title: title.value, link: '/'+link.value}];
                return { ...item, submenu: updatedSubmenu };
            } else if (item.submenu) {
                // Рекурсивно обрабатываем подменю, если оно существует
                return { ...item, submenu: addSubMenuRecursive(item.submenu) };
            }
            return item;
        });
    }
    const updateSidebarMenu = async () => {
        setMenuItems(addSubMenuRecursive(menuItems));
        const fullObject = {
            link: '/'+link.value,
            title: title.value,
            titleEng: titleEng.value,
            parent: isOpen.link
        }
        await fetch(`${api_url}/link`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem('tokenWiki')

            },
            body: JSON.stringify(fullObject)
        })
    }
    const updatePagesList = async () => {
        const fullObject = {
            link: '/'+link.value,
            title: title.value,
            titleEng: titleEng.value,
            content: content.value,
            contentEng: contentEng.value
        }
        const res = await fetch(`${api_url}/page`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem('tokenWiki')
            },
            body: JSON.stringify(fullObject)
        })
        if (res.status === 200) {
            return true
        } else {
            setLink({...link, err: ' Ссылка уже занята'})
            return false
        }
    }
    const updatePhoto = async () => {
        let photosName = extractImageFilenames(content.value);
        let photosNameEng = extractImageFilenames(contentEng.value);
        const combinedUniqueArray = Array.from(new Set(photosName.concat(photosNameEng)));
        const formData = new FormData();
        const filesToUpload = photos.filter(file => combinedUniqueArray.includes(file.name));
        
        for (let file of filesToUpload) {
            formData.append('photos', file);
        }
        try {
            const res = await fetch(`${api_url}/photo`, {
                method: 'POST',
                headers: {
                    "Authorization": localStorage.getItem('tokenWiki')
                },
                body: formData
            })
           
          } catch (error) {
            console.error('Error uploading files:', error);
          }
        };
    const handleSubmit = async () => {
    
        if (isInputValid() && isPhotoValid()) {
            setLoad(true);
            const res =  await updatePagesList();
            if (res) {
                await updateSidebarMenu();
                await updatePhoto();
                setSelectedPage('/'+link.value);
                needUpdateSidebar(!updateFlag);
                setTimeout(()=>{
                    setLoad(false);
                    handleClose();
                    navigate(`${link.value}`);
                }, 1000)
            } else {
                setLoad(false);
            }
        }
    }

    useEffect(() => {
        setBlockScroll(isOpen.value);
        setTitleEng({value: '', err: ''});
        setTitle({value: '', err: ''});
        setLink({value: '', err: ''});
        setContent({value: '', err: ''});
        setContentEng({value: '', err: ''});
        setFile([]);
        setFileEng([]);
        setPhotos([]);
        setNotUploaded([]);
        setNeedPhoto([]);
        // eslint-disable-next-line
    }, [isOpen]);

    return (
        <Modal
            open={isOpen.value}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={isOpen.value}>
                <Box sx={style}>
                    <ModalHeader>
                        <ModalTitle>Создание подстраницы</ModalTitle>
                    </ModalHeader>
                    <CloseContainer >
                        <CloseButton type="button" onClick={handleClose}>
                            <span>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" stroke="none" aria-hidden="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
                                            <path fill="currentColor" fillRule="evenodd" d="M3.47 3.47a.75.75 0 0 1 1.06 0L8 6.94l3.47-3.47a.75.75 0 1 1 1.06 1.06L9.06 8l3.47 3.47a.75.75 0 1 1-1.06 1.06L8 9.06l-3.47 3.47a.75.75 0 0 1-1.06-1.06L6.94 8 3.47 4.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd">
                                            </path>
                                        </svg>
                                    </svg>
                                </span>
                            </span>
                        </CloseButton>
                    </CloseContainer>
                    <ModalBody>
                        <InputContainer>
                            <div className="title">
                                Название
                            </div>
                            <InputWrapper>
                                <ModalTextInput className={title.err ? 'error': ''} type="text" value={title.value} onChange={(e)=>handleInputChange(e, setTitle)}/>
                                {title.err && <ErrorMsg>{title.err}</ErrorMsg>}
                            </InputWrapper>
                        </InputContainer>
                        <InputContainer>
                            <div className="title">
                                Название (Eng)
                            </div>
                            <InputWrapper>
                                <ModalTextInput className={titleEng.err ? 'error': ''} type="text" value={titleEng.value} onChange={(e)=>handleInputChange(e, setTitleEng)}/>
                                {titleEng.err && <ErrorMsg>{titleEng.err}</ErrorMsg>}
                            </InputWrapper>
                        </InputContainer>
                        <InputContainer>
                            <div className="title">
                                Адрес страницы
                            </div>
                            <InputWrapper>
                                <ModalTextInput className={link.err ? 'error': ''} type="text" value={link.value} onChange={(e)=>handleInputChange(e, setLink)}/>
                                {link.err && <ErrorMsg>{link.err}</ErrorMsg>}
                            </InputWrapper>
                        </InputContainer>
                        <LinkPreview>
                            <div className='title'>
                                Полный адрес страницы
                            </div>
                            {link.value.trim().length === 0 ?
                                <span>Чтобы увидеть полный адрес, заполните поле «Адрес страницы».</span>
                                :
                                <span>{`/knowledge-base/${link.value}`}</span>
                            }
                        </LinkPreview>
                        <InputContainer>
                            <div className="title">
                                Файл
                            </div>
                            <FileContainer>
                                <UploadButton className={content.err ? 'error' : ''} type="button" onClick={() => fileInputRef.current.click()}>
                                   Выберите файл
                                </UploadButton>
                                <input accept=".docx, .txt"
                                       onChange={handleFileChange}
                                       autoComplete="off" type="file"
                                       ref={fileInputRef}/>
                                <FileInputLabel>
                                    {file.length === 0 ?
                                        <span>Поддерживаются форматы .docx, .txt</span>
                                        :
                                        <span>{file.name}</span>
                                    }
                                </FileInputLabel>
                            </FileContainer>
                        </InputContainer>
                        <InputContainer>
                            <div className="title">
                                Файл (Eng)
                            </div>
                            <FileContainer>
                                <UploadButton className={contentEng.err ? 'error' : ''} type="button" onClick={() => fileInputRefEng.current.click()}>
                                    Выберите файл
                                </UploadButton>
                                <input accept=".docx, .txt"
                                       onChange={handleFileChangeEng}
                                       autoComplete="off" type="file"
                                       ref={fileInputRefEng}/>
                                <FileInputLabel>
                                    {fileEng.length === 0 ?
                                        <span>Поддерживаются форматы .docx, .txt</span>
                                        :
                                        <span>{fileEng.name}</span>
                                    }
                                </FileInputLabel>
                            </FileContainer>
                        </InputContainer>
                        <InputContainer>
                            <div className="title">
                                Фото
                            </div>
                            <FileContainer>
                                <UploadButton type="button" onClick={() => photoInputRef.current.click()}>
                                    Выберите фото
                                </UploadButton>
                                <input
                                    type="file"
                                    multiple
                                    onChange={handlePhotoSelect}
                                    ref={photoInputRef}
                                />
                                <FileInputLabel>  
                                    <span> Загружено {photos.length} фото</span>
                                </FileInputLabel>
                            </FileContainer>
                        
                        </InputContainer>
                        {notUploaded.length > 0 &&
                            <>
                                <p style={{color: '#ec6d6b'}}>Не хватает фото:</p>
                                <div style={{display: 'flex', gap: '12px', color: '#ec6d6b', flexWrap: 'wrap'}}>
                                {notUploaded.map((item,index)=>(
                                    <span key={index}>{item}</span>
                                ))}
                                </div>
                            </>
                        }
                        
                        <ModalFooter>
                            <FooterButton type='button' className={load? 'load' : ''} onClick={handleClose} disabled={load}>
                                Отмена
                            </FooterButton>
                            <FooterButton type='button' className={load? 'load' : 'submit'} onClick={handleSubmit} disabled={load} >
                                Создать
                            </FooterButton>
                        </ModalFooter>
                    </ModalBody>
                </Box>
            </Fade>
        </Modal>
    );
};

export default Index;

const ModalTitle = styled.div`
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
`
const ModalHeader = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  line-height: 24px;
  padding: 20px calc( 32px + 28px) 10px 32px;
`
const CloseContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 14px;
  z-index: 1;
`
const CloseButton = styled.button`
    width: 28px;
    height: 28px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #fff;
    background: transparent;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    transition: all .15s ease-out;
    &:hover {
      background: rgba(255, 255, 255, 0.25);
    }
`
const InputContainer = styled.div`
    display: flex;
    margin-bottom: 20px;
     .title {
      flex-shrink: 0;
      margin-top: 5px;
      width: 120px;
    }
`
const ModalBody = styled.div`
    padding: 10px 32px;
  font-size: 13px;
`
const ModalTextInput = styled.input`
  display: inline-block;
  width: 100%;
  background: transparent;
  color: #fff;
  border: 1px solid hsla(0,0%,100%,.15);
  border-radius: 6px;
  height: 26px;
  padding: 5px 8px;
  outline: none;
  &.error {
    border: 1px solid #ec6d6b;
  }
  &:focus {
    outline: 1px solid #fff;
  }
  
`
const LinkPreview = styled.div`
  background: hsla(0,0%,100%,.1);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 8px 12px;
  word-break: break-all;
  .title {
    font-weight: 500;
    margin-bottom: 4px;
  }
`
const FileContainer = styled.div`
  align-items: center;
  display: flex;
  input {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`
const UploadButton = styled.button`
  height: 28px;
  line-height: 28px;
  font-weight: 400;
  font-size: 13px;
  white-space: nowrap;
  border-radius: 6px;
  padding: 0 12px;
  border: none;
  cursor: pointer;
  transition: all .15s ease-out;
  color: #fff;
  background: hsla(0,0%,100%,.1);
  &.error {
    border: 1px solid #ec6d6b;
  }
  &:hover {
    background: hsla(0,0%,100%,.25);
  }
`
const FileInputLabel = styled.div`
  color: hsla(0,0%,100%,.6);
  margin-left: 8px;
  overflow: hidden;
  align-items: center;
  display: flex;
`
const ModalFooter = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  padding-bottom: 16px 0;
`
const FooterButton = styled.button`
  height: 36px;
  line-height: 36px;
  font-weight: 400;
  font-size: 13px;
  white-space: nowrap;
  border-radius: 8px;
  padding: 0 16px;
  border: none;
  cursor: pointer;
  transition: all .15s ease-out;
  color: #fff;
  background: hsla(0, 0%, 100%, .1);

  &.submit {
    background: hsl(0deg 0% 53.94%);
  }
  &.load {
    background: linear-gradient(120deg, rgba(73, 73, 73, 0.94), rgba(131, 131, 131, 0.75), rgba(73, 73, 73, 0.94));
    background-size: 300% 100%;
    animation: gradientMove 1s ease infinite;
    pointer-events: none;
  }
  @keyframes gradientMove {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 50%;
    }
  }

  &:hover {
    background: hsla(0, 0%, 100%, .25);
  }
`
const ErrorMsg = styled.div`
  color: #ec6d6b;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 2px;
  white-space: normal;
`
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`