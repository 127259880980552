import React, {useEffect, useState} from 'react';
import {useRecoilState, useSetRecoilState, useRecoilValue} from "recoil";
import {Alert, Lang, SupportModal} from "../../../state/atoms";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import styled from 'styled-components';
import {dictionary} from "../../../constants/dictionary";



const Index = () => {
    const [isOpen, setOpen] = useRecoilState(SupportModal);
    const [name, setName] = useState({value: '', err: ''});
    const [post, setPost] = useState({value: '', err: ''});
    const [email, setEmail] = useState({value: '', err: ''});
    const [question, setQuestion] = useState({value: '', err: ''});
    const [tel, setTel] = useState({value: '', err: ''});
    const [load, setLoad] = useState(false);
    const setAlert = useSetRecoilState(Alert);
    const lang = useRecoilValue(Lang);
    const langDictionary = dictionary[lang] || {};
    const handleClose = () => {
        setOpen(false);
    }
    const handleInputChange = (e, setState) => setState({err: '', value: e.target.value});
    const isInputValid = () => {
        let isValid = true;
        if (name.value.trim().length === 0) {
            setName({...name, err: langDictionary.supportModalInputErr})
            isValid = false;
        }
        if (email.value.trim().length === 0) {
            setEmail({...email, err: langDictionary.supportModalInputErr})
            isValid = false;
        }
        if (question.value.trim().length === 0) {
            setQuestion({...question, err: langDictionary.supportModalInputErr})
            isValid = false;
        }
        if (tel.value.trim().length === 0) {
            setTel({...tel, err: langDictionary.supportModalInputErr})
            isValid = false;
        }
        return isValid;
    }
    const sendMsg = async () => {
        const botToken = '6401582093:AAHkRiYsEF6O3yKUO7QrP7dWrZUVwGo-e98';
        const chatId = '358267174';
        const apiUrl = `https://api.telegram.org/bot${botToken}/sendMessage`;
        const message =`ODRS\n\n\nИмя: ${name.value}\n\nДолжность: ${post.value}\n\nПочта: ${email.value}\n\nТелефон: ${tel.value}\n\nВопрос: ${question.value}`
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                chat_id: chatId,
                text: message,
            }),
        })
        if (response.status === 200) {
            setAlert({display: true, msg: langDictionary.supportModalAlertSuccess, isErr: false})
        } else {
            setAlert({display: true, msg: langDictionary.supportModalAlertError, isErr: true})
        }

    }
    const handleSubmit = async () => {
        if (isInputValid()) {
            setLoad(true);
            await sendMsg();
            setTimeout(()=>{
                setLoad(false);
                handleClose();
            }, 1000)
        }
    }

    useEffect(() => {
        setName({value: '', err: ''});
        setPost({value: '', err: ''});
        setQuestion({value: '', err: ''});
        setEmail({value: '', err: ''});
    }, [isOpen]);

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={isOpen}>
                <ModalStyle>
                    <ModalHeader>
                        <ModalTitle>{langDictionary.supportModalTitle}</ModalTitle>
                    </ModalHeader>
                    <CloseContainer >
                        <CloseButton type="button" onClick={handleClose}>
                            <span>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" stroke="none" aria-hidden="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
                                            <path fill="currentColor" fillRule="evenodd" d="M3.47 3.47a.75.75 0 0 1 1.06 0L8 6.94l3.47-3.47a.75.75 0 1 1 1.06 1.06L9.06 8l3.47 3.47a.75.75 0 1 1-1.06 1.06L8 9.06l-3.47 3.47a.75.75 0 0 1-1.06-1.06L6.94 8 3.47 4.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd">
                                            </path>
                                        </svg>
                                    </svg>
                                </span>
                            </span>
                        </CloseButton>
                    </CloseContainer>
                    <ModalBody>
                        <InputContainer>
                            <div className="name">
                                {langDictionary.supportModalInputName} *
                            </div>
                            <InputWrapper>
                                <ModalTextInput className={name.err ? 'error': ''} type="text" value={name.value} onChange={(e)=>handleInputChange(e, setName)}/>
                                {name.err && <ErrorMsg>{name.err}</ErrorMsg>}
                            </InputWrapper>
                        </InputContainer>
                        <InputContainer>
                            <div className="name">
                                {langDictionary.supportModalInputDolzh}
                            </div>
                            <InputWrapper>
                                <ModalTextInput className={post.err ? 'error': ''} type="text" value={post.value} onChange={(e)=>handleInputChange(e, setPost)}/>
                                {post.err && <ErrorMsg>{post.err}</ErrorMsg>}
                            </InputWrapper>
                        </InputContainer>
                        <InputContainer>
                            <div className="name">
                                Email *
                            </div>
                            <InputWrapper>
                                <ModalTextInput className={email.err ? 'error': ''} type="email" value={email.value} onChange={(e)=>handleInputChange(e, setEmail)}/>
                                {email.err && <ErrorMsg>{email.err}</ErrorMsg>}
                            </InputWrapper>
                        </InputContainer>
                        <InputContainer>
                            <div className="name">
                                {langDictionary.supportModalInputTel} *
                            </div>
                            <InputWrapper>
                                <ModalTextInput className={tel.err ? 'error': ''} type="tel" value={tel.value} onChange={(e)=>handleInputChange(e, setTel)}/>
                                {tel.err && <ErrorMsg>{tel.err}</ErrorMsg>}
                            </InputWrapper>
                        </InputContainer>
                        <InputContainer>
                            <div className="name">
                                {langDictionary.supportModalInputQuest} *
                            </div>
                            <InputWrapper>
                                <QuestionTextInput style={{height: '50px'}} className={question.err ? 'error': ''} type="text" value={question.value} onChange={(e)=>handleInputChange(e, setQuestion)}/>
                                {question.err && <ErrorMsg>{question.err}</ErrorMsg>}
                            </InputWrapper>
                        </InputContainer>
                        <ModalFooter>
                            <FooterButton type='button' className={load? 'load' : ''} onClick={handleClose} disabled={load}>
                                {langDictionary.supportModalButtonCancel}
                            </FooterButton>
                            <FooterButton type='button' className={load? 'load' : 'submit'} onClick={handleSubmit} disabled={load} >
                                {langDictionary.supportModalButtonSubmit}
                            </FooterButton>
                        </ModalFooter>
                    </ModalBody>
                </ModalStyle>
            </Fade>
        </Modal>
    );
};

export default Index;

const ModalStyle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 640px;
  background: #474747;
  border-radius: 8px;
  box-shadow: 24;
  @media screen and (max-width: 880px){
    width: 100%;
    height: 100%;
  }
`

const ModalTitle = styled.div`
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
  @media screen and (max-width: 880px){
   font-size:22px;
  }
`
const ModalHeader = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  line-height: 24px;
  padding: 20px calc( 32px + 28px) 10px 32px;
`
const CloseContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 14px;
  z-index: 1;
`
const CloseButton = styled.button`
    width: 28px;
    height: 28px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #fff;
    background: transparent;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    transition: all .15s ease-out;
    &:hover {
      background: rgba(255, 255, 255, 0.25);
    }
`
const InputContainer = styled.div`
    display: flex;
    margin-bottom: 20px;
     .name {
      flex-shrink: 0;
      margin-top: 5px;
      width: 120px;
    }
  @media screen and (max-width: 880px){
   flex-direction: column;
    gap: 10px;
    font-size: 18px;
  }
 
`
const ModalBody = styled.div`
    padding: 10px 32px;
  font-size: 13px;
`
const ModalTextInput = styled.input`
  display: inline-block;
  width: 100%;
  background: transparent;
  color: #fff;
  border: 1px solid hsla(0,0%,100%,.15);
  border-radius: 6px;
  height: 26px;
  padding: 5px 8px;
  outline: none;
  &.error {
    border: 1px solid #ec6d6b;
  }
  &:focus {
    outline: 1px solid #fff;
  }
  @media screen and (max-width: 880px){
    padding: 10px;
    height: 100%;
  }
`

const ModalFooter = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  padding-bottom: 16px;
`
const FooterButton = styled.button`
  height: 36px;
  line-height: 36px;
  font-weight: 400;
  font-size: 13px;
  white-space: nowrap;
  border-radius: 8px;
  padding: 0 16px;
  border: none;
  cursor: pointer;
  transition: all .15s ease-out;
  color: #fff;
  background: hsla(0, 0%, 100%, .1);

  &.submit {
    background: hsl(0deg 0% 53.94%);
  }
  &.load {
    background: linear-gradient(120deg, rgba(73, 73, 73, 0.94), rgba(131, 131, 131, 0.75), rgba(73, 73, 73, 0.94));
    background-size: 300% 100%;
    animation: gradientMove 1s ease infinite;
    pointer-events: none;
  }
  @keyframes gradientMove {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 50%;
    }
  }

  &:hover {
    background: hsla(0, 0%, 100%, .25);
  }
  @media screen and (max-width: 880px){
    font-size: 18px;
  }
`
const ErrorMsg = styled.div`
  color: #ec6d6b;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 2px;
  white-space: normal;
  @media screen and (max-width: 880px){
    font-size: 14px;
  }
`
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const QuestionTextInput = styled.textarea`
  min-height: 50px;
  display: inline-block;
  width: 100%;
  background: transparent;
  color: #fff;
  border: 1px solid hsla(0,0%,100%,.15);
  border-radius: 6px;
  padding: 5px 8px;
  outline: none;
  &.error {
    border: 1px solid #ec6d6b;
  }
  &:focus {
    outline: 1px solid #fff;
  }
`
