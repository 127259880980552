export const dictionary = {
    ru: {
        notFound: 'Страница не найдена',
        homePageTitle: 'Главная',
        homePageMainText: 'Найдите ответы на все вопросы, ',
        homePageSpanText: 'связанные с фреймворком ODRS',
        homePageSubTitle: 'Выберите категорию, чтобы найти необходимую помощь',
        homePageItem1Title: 'База знаний',
        homePageItem1Text: 'База знаний – источник полезной информации, руководств и ресурсов, предназначенных для оптимизации и улучшения вашего опыта в использовании системы рекомендаций.',
        homePageItem2Title: 'Документы',
        homePageItem2Text: 'Документы - центр хранения основных стайтей, презентаций, инструкций, отчетов косаемых фреймворка ODRS',
        homePageItem3Title: 'Видеоуроки',
        homePageItem3Text: 'Обучающие видеоуроки - ваш источник видеоматериалов, созданных для облегчения процесса обучения и понимания системы ODRS.',
        homePageSupportTitle: 'Дополнительная ',
        homePageSupportSpan: 'поддержка',
        homePageSupportSubTitle: 'Не смогли найти то, что искали? Запросить поддержку по электронной почте:',
        homePageTextButton: 'Запросить поддержку',
        langRu: 'Рус',
        langEn: 'Eng',
        supportModalTitle: 'Оставьте свой вопрос',
        supportModalInputName: 'Имя',
        supportModalInputDolzh: 'Должность',
        supportModalInputTel: 'Телефон',
        supportModalInputQuest: 'Вопрос',
        supportModalButtonCancel: 'Отмена',
        supportModalButtonSubmit: 'Отправить',
        supportModalInputErr: 'Необходимо заполнить поле',
        supportModalAlertSuccess: 'Заявка успешно отправлена',
        supportModalAlertError: 'Произошла ошибка',
    },
    en: {
        notFound: 'Page not found',
        homePageTitle: 'Main',
        homePageMainText: 'Find answers to all questions related to the ',
        homePageSpanText: 'ODRS framework',
        homePageSubTitle: 'Choose a category to find the help you need',
        homePageItem1Title: 'Knowledge Base',
        homePageItem1Text: 'The Knowledge Base is a source of useful information, guides and resources designed to optimize and improve your experience with ODRS. Find answers to questions, solutions to problems, and helpful articles here to maximize your use of our tools and products.',
        homePageItem2Title: 'Documents',
        homePageItem2Text: 'Documents - a storage center for the main technical manuals, instructions and documentation required for the installation, configuration and successful operation of the ODRS. Here you will find all the information you need to ensure the reliable and efficient operation of our products.',
        homePageItem3Title: 'Video tutorials',
        homePageItem3Text: 'Training Video Tutorials are your source for videos designed to help you learn and understand ODRS. Here you will find visual lessons, functionality demonstrations and tips on how to best utilize our products. Learn step by step through the videos to become a proficient user of the our system.',
        homePageSupportTitle: 'More ',
        homePageSupportSpan: 'support',
        homePageSupportSubTitle: 'Could not find what you were searching for? Request support via e-mail:',
        homePageTextButton: 'Create Support Ticket',
        langRu: 'Рус',
        langEn: 'Eng',
        supportModalTitle: 'Open a new ticket',
        supportModalInputName: 'Name',
        supportModalInputDolzh: 'Position',
        supportModalInputTel: 'Phone',
        supportModalInputQuest: 'Question',
        supportModalButtonCancel: 'Cancel',
        supportModalButtonSubmit: 'Send',
        supportModalInputErr: 'It is necessary to fill in the field',
        supportModalAlertSuccess: 'Your question has been successfully sent',
        supportModalAlertError: 'There was an error',
    }
}